import DashboardLayout from "../components/DashboardLayout";
//https://github.com/Typeform/embed/issues/293
import { Widget } from '@typeform/embed-react/build'

const Survey = () => {
	return (
		<DashboardLayout>
			<Widget
				id="nTj1QnCT"
				className="h-full"
				onSubmit={(event) => {
					console.log("onsubmit: ", event);
				}}
			/>
		</DashboardLayout>
	)

}

export default Survey;