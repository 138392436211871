import { useCarbonStatus, useMyMonthlyStats, useUserData } from "../lib/hooks";

import { Bar, defaults, Line } from 'react-chartjs-2';
import moment from "moment";
import { useMemo } from "react";
import { humanizeCO2eAmount } from "../lib/util";

const COLORS = [
	'#3d708f',
	'#6996b3',
	'#94bed9',
	'#c1e7ff',
	'#ffa600',
	'#2E856E',
	'#99AEA2',
	'#0E6A79',
	'#2EB62C',
	'#4dc9f6',
	'#f67019',
	'#f53794',
	'#537bc4',
	'#acc236',
	'#166a8f',
	'#00a950',
	'#58595b',
];

defaults.animation = false;

export function color(index: number) {
	return COLORS[index % COLORS.length];
}

const EmissionGraph = ({ displayTitle, height }: { displayTitle: boolean; height?: number; }) => {
	const { emissionStats, emissionCategoryMap } = useMyMonthlyStats();
	const uniqueCategories = useMemo(() => {
		if (emissionCategoryMap["baseline"]) {
			return [emissionCategoryMap["baseline"][0]]
				.concat(Object.keys(emissionCategoryMap).filter(x => x !== "baseline").map(x => emissionCategoryMap[x][0]).filter((v, i, a) => a.indexOf(v) === i));
		}

		return Object.keys(emissionCategoryMap).map(x => emissionCategoryMap[x][0]).filter((v, i, a) => a.indexOf(v) === i);
	}, [emissionCategoryMap]);

	//console.log(uniqueCategories);

	// all months
	const all_months = (Object.keys(emissionStats) || [])
		.filter(ts => moment().add(-1, 'year').isBefore(moment(ts)))
		.map(ts => moment(ts).utc().format("YYYY/MM"))
		.filter((v, i, self) => self.indexOf(v) === i)
	//console.log({all_months});


	const datasets = useMemo(
		() => {
			let cats = uniqueCategories.map((c, i) => ({
				label: c,
				data: (Object.keys(emissionStats) || []).filter(
					ts => moment().add(-1, 'year').isBefore(moment(ts))
				).map(ts => ({
					x: moment(ts).utc().format("YYYY/MM"),
					y: Object.keys(emissionStats[ts].emission_by_category).filter(x => emissionCategoryMap[x][0] === c).map(catId => emissionStats[ts].emission_by_category[catId]).reduce((a, b) => a + b, 0),
				})),
				backgroundColor: color(i),
			}));
			return cats;
		}, [emissionCategoryMap, emissionStats, uniqueCategories])

	//console.log(datasets)

	const options = {
		plugins: {
			title: {
				display: displayTitle,
				text: 'Carbon emissions by month',
				font: {
					size: 16,
					family: '"DM Sans"',
				},
				color: '#083232',
			},
			legend: {
				labels: {
					font: {
						size: 14,
						family: '"DM Sans"',
					},
				},
				position: 'bottom',
			}
		},
		legend: {
			display: false
		},
		responsive: true,
		scales: {
			y: {
				stacked: true,
				ticks: {
					// Include a dollar sign in the ticks
					callback: function (value: any) {
						return `${humanizeCO2eAmount(value, 'kg', 'ton')} CO2e`
					},
					stepSize: 1000,
				},
				grid: {
					display: false,
				}
			},
			x: {
				stacked: true,
				grid: {
					display: false,
				}
			},
		},
	};


	return (
		<Bar
			className="font-dm-sans"
			type="bar"
			data={{ datasets: datasets }}
			options={options}
			height={height}
		/>
	)

}

export default EmissionGraph;