import DashboardLayout from "../components/DashboardLayout";
import EmissionGraph from '../components/EmissionGraph';

const Graph = () => {
	return (
		<DashboardLayout>
			<EmissionGraph displayTitle />
		</DashboardLayout>
	)

}

export default Graph;