import { Project } from "../lib/models";

const ProjectDescCard = ({ project, description }: { project: Project | null; description?: string }) => {

	const imageUrl = project && project.images.length > 0 ? project.images[0] : "";

	return (
		<div className="flex flex-col">
			<div className="bg-white rounded-2xl ">
				<div className="flex-none lg:flex">
					<div
						className="h-full lg:h-auto lg:w-1/4 w-48 rounded-tl-2xl rounded-bl-2xl flex-none bg-cover overflow-hidden md:block hidden"
						title="Canopi partner project"
						style={{ backgroundImage: `url(${imageUrl})` }}
					>
					</div>
					<div className="w-full md:hidden">
						<img src={imageUrl}
							alt="Canopi partner project" className="object-scale-down rounded-tl-2xl rounded-tr-2xl" />
					</div>
					<div className="flex flex-col md:ml-4 justify-between p-4">
						<div className="flex flex-wrap">
							<div className="w-full flex-none text-base font-dm-sans text-canopi-green font-bold">
								{project?.provider}
							</div>
							<h2 className="flex-auto md:text-2xl text-xl font-medium font-dm-sans">{project?.name}</h2>
						</div>
						<div className="flex flex-col py-2 text-sm text-gray-600 mb-2">
							<p className="font-dm-sans md:text-base text-base text-canopi-dark-green">
								{project?.description}
							</p>
						</div>
						<div className="flex flex-col space-y-3 md:flex-row md:space-y-0  md:space-x-3 text-sm font-medium items-center justify-end">
							<p className="text-base"><a className="underline" target="_blank" rel="noreferrer" href={project?.project_url}>Learn more</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProjectDescCard;