import { FC } from 'react';
import Nav from '../components/Nav';
import { useUserData } from '../lib/hooks';


const LogOut: FC = () => {
	const { logout } = useUserData();

	return (
		<div className="h-full w-full">
			<Nav />
			<div className="px-4 pt-6 pb-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
				<div className="container flex flex-col lg:px-12">

					<button
						className="w-36 inline-flex justify-center items-center px-4 py-2 rounded-md bg-canopi-green hover:bg-green-700 focus:outline-none focus:shadow-outline"
						onClick={() => logout()}
					>
						<span className="text-white font-bold text-base font-dm-sans leading-6">Log Out</span>
					</button>

				</div>
			</div>
		</div>
	)


}

export default LogOut;