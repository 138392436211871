import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { authRequest, Method, request } from './datalayer';
import { Calculation, CarbonStatus, MyStats, Order, PaymentMethod, Project, TimeRangeStats, User } from './models';
import { getMonthRef } from './util';

// when usePubKey is true, it uses crypto calculator API key when the user is not logged in
export const useAuthRequest = (method: Method, path: string, body?: any, use_pub_key: boolean = false, use_effect: boolean = true) => {
	const { getAccessTokenSilently, isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [isDataLoading, setDataLoading] = useState<boolean>(false);

	const loading = isDataLoading || isAuth0Loading;

	const fetchData = useCallback(async () => {
		if (isAuth0Loading) return;

		setDataLoading(true);
		try {
			let data;

			if (!isAuthenticated) {
				if (use_pub_key) {
					console.log('using crypto calc API key')
					data = await request(method, path, body);
				} else {
					throw Error("not authenticated");
				}
			} else {
				const token = await getAccessTokenSilently();
				data = await authRequest(method, path, body, token);
			}

			setData(data);
			return data;
		} catch (error) {
			setError(error);
		} finally {
			setDataLoading(false);
		}
	}, [getAccessTokenSilently, method, path, body, isAuth0Loading, isAuthenticated, use_pub_key])

	useEffect(() => {
		if (use_effect) {
			fetchData();
		}
	}, [fetchData, use_effect]);

	return { loading, error, data, refetch: fetchData };
}

export function useURLQuery() {
	return new URLSearchParams(useLocation().search);
}

export const useUserData = () => {
	const { isAuthenticated, getAccessTokenSilently, isLoading: isAuth0Loading, logout: auth0Logout } = useAuth0();
	const queryClient = useQueryClient()

	const fetchUser = useCallback(async () => {
		if (!isAuthenticated) {
			return null;
		}

		const token = await getAccessTokenSilently();
		const { user } = await authRequest(Method.GET, '/user/', null, token);
		return user;

	}, [isAuthenticated, getAccessTokenSilently])

	const logout = useCallback(async () => {
		auth0Logout();
		queryClient.invalidateQueries();

	}, [auth0Logout, queryClient])

	const invalidateUserCache = useCallback(() => {
		queryClient.invalidateQueries();
	}, [queryClient]);

	const queryData = useQuery<User | null>('user', fetchUser);
	const isLoading = isAuth0Loading || queryData.isLoading;
	const user = queryData.data;

	return { isAuthenticated, ...queryData, user, isLoading, logout, invalidateUserCache };
}

export const useMyStats = () => {
	const { getAccessTokenSilently } = useAuth0();
	const fetchData = useCallback(async () => {
		const token = await getAccessTokenSilently();
		return authRequest(Method.GET, '/user/stats', null, token);
	}, [getAccessTokenSilently]);

	const result = useQuery<MyStats>("my_stats", fetchData);
	const stats = result.data || null;

	return { ...result, stats };
}

export const useGetAPIKey = () => {
	const { getAccessTokenSilently } = useAuth0();
	const fetchData = useCallback(async () => {
		const token = await getAccessTokenSilently();
		const { api_key } = await authRequest(Method.GET, '/user/api-key', null, token);
		return api_key;
	}, [getAccessTokenSilently])

	const result = useQuery<string | undefined>('api_key', fetchData);
	const apiKey = result.data || "";

	return { ...result, apiKey }
}

export const useMyEmissionHistory = () => {
	const { getAccessTokenSilently } = useAuth0();
	const fetchData = useCallback(async () => {
		const token = await getAccessTokenSilently();
		const { calculations } = await authRequest(Method.GET, '/emission/history', null, token);
		return calculations;
	}, [getAccessTokenSilently])

	const result = useQuery<Calculation[] | undefined>('my_emission_history', fetchData);
	const calculations = result.data;

	return { ...result, calculations }
}

export const useMyMonthlyStats = () => {
	const { getAccessTokenSilently } = useAuth0();
	const fetchData = useCallback(async () => {
		const token = await getAccessTokenSilently();
		return authRequest(Method.GET, '/user/stats/monthly', null, token);
	}, [getAccessTokenSilently])

	const result = useQuery<TimeRangeStats>('my_monthly_stats', fetchData);
	const emissionStats = result.data?.emissions.stats || {};
	const emissionCategoryMap = result.data?.emissions.category_desc || {};
	const offsetStats = result.data?.orders.stats || {};


	return { ...result, emissionStats, emissionCategoryMap, offsetStats }
}

export const useProjects = (withAuth = true) => {
	const { getAccessTokenSilently, isLoading: isAuthLoading, isAuthenticated } = useAuth0();

	const fetchData = useCallback(async () => {
		if (withAuth && isAuthLoading) return;
		if (withAuth && isAuthenticated) {
			const token = await getAccessTokenSilently();
			return authRequest(Method.GET, '/removal/projects', null, token);
		} else {
			return request(Method.GET, '/removal/projects');
		}

	}, [getAccessTokenSilently, isAuthLoading, isAuthenticated, withAuth])

	const result = useQuery<Project[]>('projects', fetchData);
	const projects = result.data || [];

	return { ...result, projects }
}


export const useMyOrders = () => {
	const { getAccessTokenSilently } = useAuth0();
	const fetchData = useCallback(async () => {
		const token = await getAccessTokenSilently();
		return authRequest(Method.GET, '/removal/orders', null, token);
	}, [getAccessTokenSilently])

	const result = useQuery<Order[] | undefined>('my_orders', fetchData);
	const orders = result.data;

	return { ...result, orders }
}

export const useCreateSaveCardCheckoutSession = (success_url: string, cancel_url: string) => {
	const { loading, error, data, refetch } = useAuthRequest(Method.POST, "/user/payment-method/create-checkout-session", { success_url, cancel_url }, false, false)
	const session_id: string | undefined = data?.session_id || undefined;

	const createSession = async () => {
		const data = await refetch();
		return data.session_id as string;
	}

	return { loading, error, session_id, createSession };
}

export const useDefaultPaymentMethod = () => {
	const { getAccessTokenSilently } = useAuth0();
	const queryClient = useQueryClient()

	const fetchData = useCallback(async () => {
		const token = await getAccessTokenSilently();
		return authRequest(Method.GET, '/user/payment-method', null, token);
	}, [getAccessTokenSilently])

	const result = useQuery<any>('default_payment_method', fetchData);
	const exists: boolean = result?.data?.exists || false;
	const paymentMethod = exists ? result!.data as PaymentMethod : null;

	const invalidate = useCallback(async () => {
		queryClient.invalidateQueries('default_payment_method');
	}, [queryClient])

	return { ...result, exists, paymentMethod, invalidate }
}

export const usePachamaProject = () => {
	const [project, setProject] = useState<Project | null>(null);
	const [error, setError] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchPachamaProject = async () => {
			try {
				const p = await request(Method.GET, '/removal/project/60d2a2875c3733bf44fe16ad');
				setProject(p);
			} catch (error) {
				setError(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchPachamaProject();
	}, [])

	return { project, error, isLoading };
}

export const useCanopiFundroject = () => {
	const [project, setProject] = useState<Project | null>(null);
	const [error, setError] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchCanopiFundProject = async () => {
			try {
				const p = await request(Method.GET, '/removal/project/60efc85100de487da31af3d7');
				setProject(p);
			} catch (error) {
				setError(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchCanopiFundProject();
	}, [])

	return { project, error, isLoading };
}

export const useCreateOrder = () => {
	const { getAccessTokenSilently } = useAuth0();

	const createOrder = useCallback(async (project: Project, amount_co2_kg: number) => {
		const token = await getAccessTokenSilently();
		const order = await authRequest(Method.POST, '/removal/order', { project_id: project.id, amount_co2_kg: amount_co2_kg }, token);
		return order;
	}, [getAccessTokenSilently])

	return createOrder;
}

export const useCarbonStatus = (time_range: 'month') => {
	const { emissionStats, offsetStats, isLoading, refetch } = useMyMonthlyStats();

	const thisMonth = getMonthRef(moment().toDate())

	const thisMonthEmission = Object.keys(emissionStats).filter(x => thisMonth.isSame(moment(x))).map(x => emissionStats[x]);
	const thisMonthOffset = Object.keys(offsetStats).filter(x => thisMonth.isSame(moment(x))).map(x => offsetStats[x]);
	const totalEmission = thisMonthEmission.length > 0 ? thisMonthEmission[0].total : 0;
	const totalOffset = thisMonthOffset.length > 0 ? thisMonthOffset[0].total : 0;
	const amountToNeutralize = Math.max(0, totalEmission - totalOffset);

	let status: CarbonStatus;
	if (totalEmission === 0) {
		status = CarbonStatus.NoCalculations
	} else if (totalEmission > totalOffset) {
		status = CarbonStatus.NotCarbonNeutral;
	} else if (totalEmission === totalOffset) {
		status = CarbonStatus.CarbonNeutral;
	} else {
		status = CarbonStatus.CarbonNegative;
	}

	return { totalEmission, totalOffset, status, amountToNeutralize, isLoading, refetch };
}

export const useHasConnectedItems = () => {
	const { isLoading, stats } = useMyStats();
	const hasConnectedItems = stats && stats.connected_items > 0;
	return { isLoading, hasConnectedItems };
}
