import { updateTypeLiteralNode } from "typescript";
import { useCarbonStatus } from "../lib/hooks";
import { getThisMonthStr, humanizeCO2eAmount } from "../lib/util";
import NeutralGreenBadge from "./badges/NeutralGreenBadge";

const CarbonStatusWidgets = () => {
	const { status, totalEmission, totalOffset, isLoading } = useCarbonStatus('month');
	const statsDisplayData = [
		{ name: 'Total Emissions', stat: humanizeCO2eAmount(totalEmission, 'kg') },
		{ name: 'Total Offsets', stat: humanizeCO2eAmount(totalOffset, 'kg') },
	];
	const roundTrips = (totalEmission / 1950).toFixed(1);
	const numTrees = (totalOffset / 1000).toFixed(1);

	return (
		<dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 mt-2">
			<div className="px-4 py-5 bg-white rounded-lg overflow-hidden sm:p-6">
				<dt className="text-sm font-medium text-gray-500 truncate">Estimated CO<sub>2</sub>-eq emissions for {getThisMonthStr()}</dt>
				<dd className="mt-1 text-3xl font-semibold text-canopi-green">{humanizeCO2eAmount(totalEmission, 'kg')}</dd>
				<div className="relative">
					<div className="absolute inset-0 flex items-center" aria-hidden="true">
						<div className="w-full border-t border-gray-300" />
					</div>
					<div className="relative flex justify-end my-2">
						<span className="pr-2 bg-white text-xs font-semibold text-gray-500 uppercase">Equivalent to</span>
					</div>
				</div>
				<div className="flex flex-col items-end mt-2">
					<dd className="mt-1 text-lg font-semibold text-gray-600">✈️ {roundTrips} round-trip flights between LA and NY</dd>
				</div>
			</div>
			<div className="px-4 py-5 bg-white rounded-lg overflow-hidden sm:p-6">
				<dt className="text-sm font-medium text-gray-500 truncate">Amount of CO<sub>2</sub>-eq offset in {getThisMonthStr()}</dt>
				<dd className="mt-1 text-3xl font-semibold text-canopi-green">{humanizeCO2eAmount(totalOffset, 'kg')}</dd>
				<div className="relative">
					<div className="absolute inset-0 flex items-center" aria-hidden="true">
						<div className="w-full border-t border-gray-300" />
					</div>
					<div className="relative flex justify-end my-2">
						<span className="pr-2 bg-white text-xs font-semibold text-gray-500 uppercase">Equivalent to</span>
					</div>
				</div>
				<div className="flex flex-col items-end mt-2">
					<dd className="mt-1 text-lg font-semibold text-gray-600">{`🌲 ${numTrees} tree${totalOffset / 1000 > 1 && "s"} planted`}</dd>
				</div>
			</div>
		</dl>
	)

}

export default CarbonStatusWidgets;