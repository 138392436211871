import { Project } from "../lib/models";
import { displayMoney, humanizeCO2eAmount } from "../lib/util";

const PachamaHardcoded = {
	id: "60d2a2875c3733bf44fe16ad",
	provider: "Pachama",
	name: "Amazon Portfolio",
	slug: "pachama-amazon-portfolio",
	cents_per_kg: 0.86,
	images: ["https://storage.googleapis.com/pachama-marketplace-assets/images/home/tech-reforest.gif"],
	project_url: "",
	description: "A collection of conservation projects preserving forest and fighting illegal logging at the forefront of deforestation in the Amazon Rainforest. Pachama uses satellites, drones, and computer vision to verify its carbon offset programs.",
}

const CanopiHardcoded = {
	id: "60efc85100de487da31af3d7",
	provider: "Canopi",
	name: "Climate Fund",
	slug: "canopi-climate-fund",
	cents_per_kg: 1.5,
	images: ["https://uploads-ssl.webflow.com/60a768ac976f3d8ac7d87081/60f07cb901033c6243dd66d2_canopi-climate-bg.jpg"],
	project_url: "",
	description: "Support a variety of projects around the world to offset your emissions. Our portfolio invests in projects spanning forests, oceans, direct air capture, and much more.",
}

const ProjectCard = ({ project, emission, totalCents, onClick, isOffsetLoading = false, hardcode = null, showPriceOnly = false }: { project: Project | null; emission: number; totalCents: number; onClick?: any; isOffsetLoading?: boolean; hardcode?: 'canopi' | 'pachama' | null; showPriceOnly?: boolean }) => {
	const offsetDisabled = totalCents <= 50;

	if (hardcode) {
		project = hardcode === 'canopi' ? CanopiHardcoded : PachamaHardcoded;
	}

	const imageUrl = project && project.images.length > 0 ? project.images[0] : "";

	return (
		<div className="flex flex-col">
			<div className="bg-white rounded-2xl ">
				<div className="flex-none lg:flex">
					<div
						className="h-full lg:h-auto lg:w-1/4 w-48 rounded-tl-2xl rounded-bl-2xl flex-none bg-cover overflow-hidden md:block hidden"
						title="Canopi partner project"
						style={{ backgroundImage: `url(${imageUrl})` }}
					>
					</div>
					<div className="w-full md:hidden">
						<img src={imageUrl}
							alt="Canopi partner project" className="object-scale-down rounded-tl-2xl rounded-tr-2xl" />
					</div>
					<div className="flex flex-col md:ml-4 justify-between p-4 w-full">
						<div className="flex flex-wrap">
							<div className="w-full flex-none text-base font-dm-sans text-canopi-green font-bold">
								{project?.provider}
							</div>
							<h2 className="flex-auto md:text-2xl text-xl font-medium font-dm-sans">{project?.name}</h2>
						</div>
						<div className="flex flex-col py-2 text-sm text-gray-600 mb-2">
							<p className="font-dm-sans md:text-base text-base text-canopi-dark-green">
								{project?.description}
							</p>
						</div>
						{showPriceOnly ? (
							<div className="flex flex-col space-y-3 md:flex-row md:space-y-0  md:space-x-3 text-sm font-medium justify-between items-baseline">
								<p className="font-dm-sans md:text-lg text-base font-bold text-canopi-green mr-2">
									Price: {displayMoney((project?.cents_per_kg || 0) * 1000)} per ton
								</p>
								<div className="flex flex-row items-baseline">
									<p className="font-dm-sans text-base text-canopi-dark-green mr-6">
										<a className="underline" target="_blank" rel="noreferrer" href={project?.project_url}>Learn more</a>
									</p>
									<button
										className={`bg-canopi-green disabled:opacity-50 ${offsetDisabled ? "cursor-not-allowed" : "hover:bg-green-700"}  text-white text-base font-bold font-dm-sans py-2 px-4 rounded-md focus:outline-none focus:shadow-outline mr-2 inline-flex justify-center items-center`}
										style={{ minWidth: "120px" }}
										onClick={onClick}
									>
										<span className="text-white font-bold text-base font-dm-sans leading-6">Purchase</span>
									</button>
								</div>
							</div>

						) : (
							<div className="flex flex-col space-y-3 md:flex-row md:space-y-0  md:space-x-3 text-sm font-medium justify-between items-baseline">
								<p className="font-dm-sans md:text-lg text-base font-bold text-canopi-green mr-2">
									CO<sub>2</sub> offset: {humanizeCO2eAmount(emission, 'kg')}
								</p>
								<button
									onClick={onClick}
									className={`bg-canopi-green disabled:opacity-50 ${offsetDisabled ? "cursor-not-allowed" : "hover:bg-green-700"}  text-white text-base font-bold font-dm-sans py-2 px-4 rounded-md focus:outline-none focus:shadow-outline mr-2 inline-flex justify-center items-center`}
									disabled={offsetDisabled}
									style={{ minWidth: "120px" }}
								>
									{isOffsetLoading ? (
										<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
											<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>

									) : (
										<span className="text-white font-bold text-base font-dm-sans leading-6">{displayMoney(totalCents)}</span>
									)}
								</button>
							</div>

						)

						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProjectCard;