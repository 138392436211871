import moment from 'moment';
import { useMemo } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import Table from '../components/Table';
import { useMyEmissionHistory } from '../lib/hooks';
import { Calculation, CryptoFootprint, TransactionFootprint } from '../lib/models';
import { displayMoney, humanizeCO2eAmount } from '../lib/util';

const History = () => {
	const { calculations, isLoading } = useMyEmissionHistory();
	const columns = useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'date', // accessor is the "key" in the data
			},
			{
				Header: 'Name',
				accessor: 'name', // accessor is the "key" in the data
			},
			{
				Header: 'Amount',
				accessor: 'amount', // accessor is the "key" in the data
			},
			{
				Header: 'Emission',
				accessor: 'emission',
			},
			{
				Header: 'Category',
				accessor: 'category',
			},
		],
		[]
	)

	const getTs = (c: Calculation) => {
		if (c.type === 'transaction') {
			return moment(c.transaction?.timestamp).unix();
		} else {
			return moment(c.address_details.start_ts).unix();
		}
	}

	const data = useMemo(
		() => (calculations || [])
			.filter(c => !(c.type === 'transaction' && c.transaction?.ignore))
			.sort((a, b) => getTs(b) - getTs(a))
			.map(c => {
				switch (c.type) {
					case 'transaction':
						const txn = c as TransactionFootprint
						return {
							date: moment(txn.transaction?.timestamp).format("YYYY-MM-DD"),
							name: txn.transaction?.merchant_name || txn.transaction?.name,
							amount: displayMoney((txn.transaction?.amount.value || 0) * 100.0),
							emission: humanizeCO2eAmount(txn.emission.total, 'kg', 'kg'),
							category: txn.transaction?.plaid_categories[0],
						}

					case 'crypto':
						const crypto = c as CryptoFootprint;
						return {
							date: moment(crypto.address_details.start_ts).format("YYYY-MM-DD"),
							name: "Holding " + crypto.address_details.currency,
							emission: humanizeCO2eAmount(crypto.emission.total, 'kg', 'kg'),
							amount: crypto.address_details.balance / (crypto.address_details.currency === "BTC" ? 1e8 : 1e18),
							category: crypto.address_details.address ? "address" : "HODL",
						}

					default:
						return {
							name: "Hello world",
							amount: displayMoney(1200),
							date: moment(),
						}
						break;
				}
			})
		,
		[calculations]
	)

	return (
		<DashboardLayout>
			<div className="flex flex-col w-full mb-4">
				<div className="flex flex-row w-full items-center mb-4">
					<h1 className="font-bold md:text-3xl text-xl font-dm-sans text-canopi-dark-green">Emission History</h1>
				</div>
				<Table columns={columns} data={data} loading={isLoading} />
			</div>
		</DashboardLayout>
	)

}

export default History;