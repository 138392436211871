import { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import CreateOrderOverlay from '../components/CreateOrderOverlay';
import ProjectCard from '../components/ProjectCard';
import { CANOPI_FUND_PROJECT_ID } from '../lib/constants';
import { useProjects } from '../lib/hooks';
import { Project } from '../lib/models';

const Projects = () => {
	const [open, setOpen] = useState(true);
	const [selectedProject, setSelectedProject] = useState<Project | null>(null);

	const { projects } = useProjects();

	let ps = projects.filter(x => x.id === CANOPI_FUND_PROJECT_ID).concat(projects.filter(x => x.id !== CANOPI_FUND_PROJECT_ID));

	const onClickInvest = (project: Project) => {
		setSelectedProject(project);
		setOpen(true);
	}

	return (
		<DashboardLayout>
			<div className="flex flex-col  max-w-screen-lg mb-4">
				<div className="flex flex-row w-full items-baseline justify-between mb-4">
					<h1 className="font-bold md:text-3xl text-xl font-dm-sans text-canopi-dark-green">Projects</h1>
					<p >
						<a className="underline" target="_blank" rel="noreferrer" href="https://canopi.notion.site/How-Canopi-Select-its-Partners-b3b6de04b68241d982675cc31ddf21dc">How Canopi select its partners</a>
					</p>
				</div>

				<div className="flex flex-col space-y-4">
					{ps.map(p => (
						<ProjectCard key={p.id} project={p} emission={4200} totalCents={2400} showPriceOnly onClick={() => onClickInvest(p)} />
					))}
				</div>
				<p className="md:text-base text-base font-dm-sans text-gray-500 mt-6 max-w-2xl">
					Prices include Canopi's 20% service fee
				</p>
			</div>

			{selectedProject && <CreateOrderOverlay open={open} setOpen={setOpen} project={selectedProject} />}

		</DashboardLayout>
	)
}

export default Projects;