import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { authRequest, Method } from "../lib/datalayer";

const ConnectBankButton = ({ onComplete, title }: { onComplete: any, title: string }) => {
	const { getAccessTokenSilently } = useAuth0();
	const [linkToken, setLinkToken] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const accessToken = await getAccessTokenSilently();
				const { token }: any = await authRequest(Method.POST, '/user/link/create-token', null, accessToken)
				console.log("link token created: ", token);
				setLinkToken(token as string);
			} catch (error) {
				console.error("error: ", error);
			} finally {
				setLoading(false);
			}
		}
		fetchData();
	}, [getAccessTokenSilently]);

	const onSuccess = useCallback(async (public_token, metadata) => {
		try {
			// send public_token to server
			const accessToken = await getAccessTokenSilently();
			const data = await authRequest(Method.POST, '/user/link/exchange-token', { public_token }, accessToken);
			console.log("exchanged token: ", data);

			// this is now handled by the server side
			// sync items
			//const syncedData = await authRequest(Method.POST, '/user/link/sync-items', null, accessToken);
			//console.log("synced data: ", syncedData);

			if (onComplete) onComplete();
		} catch (error) {
			console.error("error exchanging token: ", error)
		}
	}, [onComplete, getAccessTokenSilently])


	const config: Parameters<typeof usePlaidLink>[0] = {
		token: linkToken || "",
		onSuccess,
	}

	const { open, ready } = usePlaidLink(config);

	let content;

	if (loading) {
		content = (
			<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
				<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
				<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
			</svg>
		)
	} else {
		content = (
			<span className="text-white font-bold text-base font-dm-sans leading-6">{title}</span>
		)
	}


	return (
		<button
			className="w-36 inline-flex justify-center items-center px-4 py-2 rounded-md bg-canopi-green hover:bg-green-700 focus:outline-none focus:shadow-outline mt-4"
			type="submit"
			onClick={() => open()}
			disabled={!ready}
		>
			{content}
		</button>
	)
}

export default ConnectBankButton;