import { FC } from 'react';
import { useEffect } from 'react';
import Nav from '../components/Nav';
import Lottie from "lottie-react";
import BTCLottie from '../btc_lottie.json';
import { useAnalytics } from 'use-analytics';

const CheckoutSuccess: FC = () => {
	const { page } = useAnalytics();

	useEffect(() => {
		document.title = "Payment Successful - Canopi";
		page();
	}, [page]);

	return (
		<div className="h-full w-full font-dm-sans">
			<Nav />
			<section className="text-gray-600 body-font">
				<div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
					<Lottie animationData={BTCLottie} className="mb-12" style={{ minWidth: "100px" }} />
					<div className="text-center lg:w-2/3 w-full">
						<h1 className="title-font sm:text-4xl text-4xl mb-8 font-medium text-gray-900">Your payment was successful.</h1>
						<p className="mb-8 leading-relaxed text-xl">Thank you for investing in a cleaner world. We've purchased carbon removal credits and retired them on your behalf. This means that no one else can take credit for removing these tons of CO2. See you soon.</p>
						<p className="mb-8 leading-relaxed text-xl">Crypto Emission Calculator is powered by Canopi's <a className="underline" href="https://www.canopi.cash/api-docs">Climate API</a>.</p>
						<div className="flex justify-center">
							<button
								onClick={() => { (window.location as any) = "https://form.typeform.com/to/Z918Lf4B" }}
								className="inline-flex text-white bg-canopi-green border-0 py-2 px-6 focus:outline-none hover:bg-green-700 rounded text-lg">
								Request API Access
							</button>
							<button
								onClick={() => { (window.location as any) = "/crypto" }}
								className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"
							>
								Back
							</button>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default CheckoutSuccess;