import { Visa, Mastercard, Amex, Diners, Jcb, Discover, Generic } from "react-pay-icons";
import { PaymentMethod } from "../lib/models";
import Spinner from "./Spinner";

export default function PaymentMethodWidget({ paymentMethod, onAddNewCard, loading = false }: { paymentMethod: PaymentMethod | null, onAddNewCard: () => void; loading?: boolean }) {
	let cardLogo;

	switch (paymentMethod?.card_brand) {
		case "visa":
			cardLogo = <Visa style={{ margin: 0, height: 24 }} />
			break;
		case "amex":
			cardLogo = <Amex style={{ margin: 0, height: 24 }} />
			break;
		case "mastercard":
			cardLogo = <Mastercard style={{ margin: 0, height: 24 }} />
			break;
		case "diners":
			cardLogo = <Diners style={{ margin: 0, height: 24 }} />
			break;
		case "discover":
			cardLogo = <Discover style={{ margin: 0, height: 24 }} />
			break;
		case "jcb":
			cardLogo = <Jcb style={{ margin: 0, height: 24 }} />
			break;
		default:
			cardLogo = <Generic style={{ margin: 0, height: 24 }} />
			break;
	}

	let content;
	if (loading) {
		content = (
			<div className="mt-5">
				<Spinner />
			</div>
		)
	} else if (paymentMethod) {
		content = (
			<div className="mt-5">
				<div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
					<h4 className="sr-only">{paymentMethod.card_brand}</h4>
					<div className="sm:flex sm:items-center">
						{cardLogo}
						<div className="mt-3 sm:mt-0 sm:ml-4">
							<div className="text-sm font-medium text-gray-900">Ending with {paymentMethod.card_last4}</div>
							<div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
								<div>Expires {`${paymentMethod.card_exp_month}/${paymentMethod.card_exp_year}`}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		content = (
			<div className="mt-5">
				<div className="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
					<div className="sm:flex sm:items-center">
						<div className="mt-3 sm:mt-0 sm:ml-4">
							<div className="text-sm font-medium text-gray-900">Not registered</div>
						</div>
					</div >
				</div >
			</div >
		)
	}



	return (
		<div className="bg-white rounded-2xl font-dm-sans">
			<div className="px-4 py-5 sm:p-6">
				<h3 className="text-lg leading-6 font-medium text-gray-900">Payment Method</h3>
				{content}
				<div className="flex flex-col w-full items-end">
					<button
						className={`bg-canopi-green disabled:opacity-50 "cursor-not-allowed" text-white text-base font-bold font-dm-sans py-2 px-4 rounded-md focus:outline-none focus:shadow-outline mr-2 inline-flex justify-center items-center mt-5`}
						style={{ minWidth: "120px" }}
						onClick={onAddNewCard}
					>
						<span className="text-white font-bold text-base font-dm-sans leading-6">Add New Card</span>
					</button>

				</div>
			</div >
		</div >
	)
}
