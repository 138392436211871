import { useEffect } from 'react';
import ProjectCard from '../components/ProjectCard';
import { useAnalytics } from 'use-analytics';
import { useCanopiFundroject, useCarbonStatus, useHasConnectedItems, useMyStats, useURLQuery, useUserData } from '../lib/hooks';
import { calculateOffsetPrice, getMonthRef, humanizeCO2eAmount } from '../lib/util';
import { authRequest, Method } from '../lib/datalayer';
import DashboardLayout from '../components/DashboardLayout';
import Spinner from '../components/Spinner';
import ConnectBankButton from '../components/ConnectBankButton';
import { useState } from 'react';
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import EmissionGraph from '../components/EmissionGraph';
import CreateOrderOverlay from '../components/CreateOrderOverlay';
import { Widget } from '@typeform/embed-react/build';
import BaselineWidget from '../components/BaselineWidget';
import CarbonStatusWidgets from '../components/CarbonStatusWidgets';
import moment from 'moment';
import NeutralGreenBadge from '../components/badges/NeutralGreenBadge';
import { CarbonStatus } from '../lib/models';

const CarbonTracker = () => {
	const { getAccessTokenSilently } = useAuth0();
	const { refetch, status, amountToNeutralize: toNeutralOffset } = useCarbonStatus('month');
	const { track, page } = useAnalytics();
	const { project, error: projectError } = useCanopiFundroject();
	const { user, invalidateUserCache } = useUserData();
	const { isLoading: isConnectedItemsLoading, hasConnectedItems } = useHasConnectedItems();

	const [isPageLoading, setPageLoading] = useState<boolean>(false);
	const [open, setOpen] = useState(false);

	const totalCents = project ? calculateOffsetPrice(project, toNeutralOffset) : 0;

	const query = useURLQuery();
	const goNeutral = query.get("go-neutral");

	const tick = useCallback(async () => {
		if (hasConnectedItems && status === CarbonStatus.NoCalculations) {
			await refetch();
			console.log('ran refetch');
		}
	}, [refetch, hasConnectedItems, status])


	useEffect(() => {
		document.title = "Carbon Tracker";

		if (goNeutral === "true") {
			onClickInvest();
		}
		page();

		const interval = setInterval(() => {
			tick()
		}, 1000);
		return () => clearInterval(interval);

	}, [page, goNeutral, tick])

	const onConnectComplete = useCallback(async () => {
		setPageLoading(true);
		try {
			await refetch();
		} catch (error) {
			console.error(error);
		} finally {
			setPageLoading(false)
		}
	}, [refetch, setPageLoading]);

	const onClickInvest = () => {
		setOpen(true);
	}

	const onSubmitBusinessOnboarding = async (event: any) => {
		const response_id = event.response_id
		console.log("onsubmit: ", response_id)
		setPageLoading(true);
		try {
			const token = await getAccessTokenSilently();
			await authRequest(Method.POST, '/user/survey/onboarding-business/done', { response_id }, token);
			invalidateUserCache();
		} catch (error) {
			console.error("error", error);
		} finally {
			setPageLoading(false);
		}
	}

	// CONTENT
	let content;
	if (isConnectedItemsLoading || isPageLoading) {
		content = (<Spinner />)
	} else if (hasConnectedItems && status === CarbonStatus.NoCalculations) {
		content = (
			<div className="h-full flex flex-col">
				<p className="font-dm-sans text-canopi-dark-green mb-4">Calculating emissions from your bank transactions...</p>
				<Spinner />
			</div>
		)
	} else if (user && !user.business_data) {
		// start the onboarding flow
		content = (
			<div className="h-full">
				<Widget
					id="nTj1QnCT"
					className="h-full"
					onSubmit={onSubmitBusinessOnboarding}
				/>
			</div>
		)

	} else if (hasConnectedItems) {
		content = (
			<>
				<div className="flex flex-col mb-8">
					<CarbonStatusWidgets />
				</div>
				<div className="flex flex-row mt-0 justify-between items-baseline">
					<p className="font-bold md:text-2xl text-xl font-dm-sans text-canopi-dark-green">
						{user?.business_data?.organization_name ? (
							`${user.business_data.organization_name}'s carbon footprint`
						) : (
							"Your carbon footprint"
						)}
					</p>
					<p >
						<a className="underline" target="_blank" rel="noreferrer" href="https://canopi.notion.site/How-we-Calculate-your-Company-s-Emissions-f56a1af54fc74707ab7d42f9543f4873">How we calculate your footprint</a>
					</p>
				</div>
				<div className="flex flex-col mt-6">
					<div className="px-4 pt-4 pb-2 bg-white rounded-2xl overflow-hidden">
						<EmissionGraph displayTitle={false} height={100} />
					</div>
				</div>
				<div className="flex flex-col mt-8">
					<p className="font-bold md:text-2xl text-xl font-dm-sans text-canopi-dark-green">
						Go carbon {toNeutralOffset === 0 ? "negative" : "neutral"} with
					</p>
				</div>
				<div className="flex flex-col mt-6 pb-6">
					{toNeutralOffset === 0 ? (
						<>
							<ProjectCard project={project} onClick={() => onClickInvest()} totalCents={4200} emission={2400} showPriceOnly />
							{project && <CreateOrderOverlay open={open} setOpen={setOpen} project={project} />}
						</>

					) : (
						<>
							<ProjectCard project={project} onClick={() => onClickInvest()} totalCents={totalCents} emission={toNeutralOffset} />
							{project && <CreateOrderOverlay open={open} setOpen={setOpen} project={project} hardcodeAmountCO2kg={toNeutralOffset} />}
						</>
					)}
				</div>
			</>
		)
	} else {
		// no stats
		const description = user?.business_data && `a ${user.business_data.industry.toLowerCase()} company with ${user.business_data.num_employees} employee${user.business_data.num_employees > 1 && "s"}`
		content = (
			<>
				<div className="flex flex-col mb-8 max-w-3xl">
					<p className="md:text-base text-base font-dm-sans text-canopi-dark-green mt-2">
						We're almost done calculating your emissions. Just connect to your corporate bank account and you'll see your results.
					</p>
				</div>
				<div className="flex flex-col mb-8 max-w-3xl">
					<div className="flex flex-col">
						<h2 className="font-bold md:text-2xl text-xl font-dm-sans text-canopi-dark-green">
							Connect Your Bank Account
						</h2>
						<p className="md:text-base text-base font-dm-sans text-canopi-dark-green mt-2">
							By connecting to your bank account, you'll be able to see emissions from flights, car rides, software spend, and other categories. Information is updated in near real-time, so you can hit up happy hour and immediately go carbon neutral.
						</p>
						<p className="md:text-base text-base font-dm-sans text-canopi-dark-green mt-2">
							Add the bank account you use for most expenses. We are working on adding multi-account support in the near future.
						</p>
						<p className="md:text-base text-base font-dm-sans text-canopi-dark-green mt-2">
							We use the same bank-level security and data encryption as Venmo, and your information is never stored on your device. Bank connections are powered by Plaid.
						</p>
						<ConnectBankButton onComplete={() => onConnectComplete()} title="Connect" />
					</div>
				</div>
			</>
		);
	}


	return (
		<DashboardLayout>
			<div className="flex flex-col w-full mb-4 h-full">
				<div className="space-y-6 lg:col-start-1 lg:col-span-2">
					<div className="flex flex-row w-full mb-4 justify-between">
						<h1 className="font-bold md:text-3xl text-xl font-dm-sans text-canopi-dark-green">Carbon Tracker</h1>
					</div>
				</div>
				{content}
			</div>
		</DashboardLayout>
	)

}

export default CarbonTracker;