import BaselineWidget from "../components/BaselineWidget";
import DashboardLayout from "../components/DashboardLayout";
import { useUserData } from "../lib/hooks";

const Baseline = () => {
	const { user } = useUserData();

	if (!user?.business_data) {
		return null;
	}

	const title = `${user.business_data.organization_name}'s baseline`

	const description = `A ${user.business_data.industry} company in ${user.business_data.location} with ${user.business_data.num_employees} employee${user.business_data.num_employees > 1 && "s"}`

	return (
		<DashboardLayout>
			<div className="flex flex-col mt-6">
				<p className="font-bold md:text-2xl text-xl font-dm-sans text-canopi-dark-green">
					{title}
				</p>
				<BaselineWidget monthly_total={user.business_data.baseline_emissions.monthly_total || 0} />
				<p className="md:text-lg text-base font-dm-sans text-canopi-dark-green mt-2">
					{description}
				</p>
			</div>

		</DashboardLayout>
	)

}

export default Baseline;