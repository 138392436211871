import React from 'react'
import { useTable } from 'react-table'
import Empty from './Empty';
import Spinner from './Spinner';


function Table({ columns, data, loading = false, emptyTitle = "Not found", emptyDesc = "" }: { columns: any; data: any; loading?: boolean; emptyTitle?: string; emptyDesc?: string }) {
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	})

	let tableBodyContent;

	if (loading) {
		tableBodyContent = (
			<tr className="border-b border-gray-200"
			>
				<td className="py-6 px-6" colSpan={42}>
					<div>
						<Spinner />
					</div>
				</td>
			</tr>
		)
	} else if (rows.length > 0) {
		tableBodyContent = rows.map((row, i) => {
			prepareRow(row)
			return (
				<tr {...row.getRowProps()}
					className="border-b border-gray-200 hover:bg-gray-50"
				>
					{row.cells.map(cell => {
						return <td {...cell.getCellProps()} className="py-2 px-6 whitespace-nowrap">{cell.render('Cell')}</td>
					})}
				</tr>
			)
		})
	} else {
		tableBodyContent = (
			<tr className="border-b border-gray-200"
			>
				<td className="py-2 px-6 whitespace-nowrap" colSpan={42}>
					<Empty title={emptyTitle} description={emptyDesc} />
				</td>
			</tr>
		)
	}


	// Render the UI for your table
	return (
		<table {...getTableProps()} className="min-w-max w-full table-auto">
			<thead
				className="overflow-hidden"
			>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}
						className="bg-gray-100 text-canopi-dark-green uppercase md:text-base text-sm leading-normal"
					>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()} className="py-3 px-6 text-left">{column.render('Header')}</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}
				className="bg-white text-gray-600 md:text-lg text-base font-light"
			>
				{tableBodyContent}
			</tbody>
		</table>
	)
}
export default Table;