import { humanizeCO2eAmount } from "../lib/util";

const BaselineWidget = ({ monthly_total }: { monthly_total: number }) => {
	return (
		<div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 max-w-xs mt-4">
			<dt className="text-sm font-medium text-gray-500 truncate">Monthly emissions</dt>
			<dd className="mt-1 text-3xl font-semibold text-canopi-green">{humanizeCO2eAmount(monthly_total, 'kg')}</dd>
		</div>
	)
}

export default BaselineWidget;