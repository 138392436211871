import { FC } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import CheckoutSuccess from './routes/CheckoutSuccess';
import CryptoCalculator from './routes/CryptoCalculator';
import NotFound from './routes/NotFound';
import LogIn from './routes/LogIn';
import LogOut from './routes/LogOut';
import { useAuth0 } from '@auth0/auth0-react';
import CarbonTracker from './routes/CarbonTracker';
import ConnectBank from './routes/ConnectBank';
import Example from './components/Example';
import APIKey from './routes/APIKey';
import Billing from './routes/Billing';
import History from './routes/History';
import ReceiptDemo from './routes/ReceiptDemo';
import Graph from './routes/Graph';
import Projects from './routes/Projects';
import ReceiptDemo2 from './routes/ReceiptDemo2';
import Orders from './routes/Orders';
import { QueryClient, QueryClientProvider } from 'react-query';
import Survey from './routes/Survey';
import Baseline from './routes/Baseline';
import ReceiptDemoRouter from './routes/ReceiptDemoRouter';

const queryClient = new QueryClient();


const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <Route {...rest} render={(props) => (
      isLoading ? <div /> : (
        isAuthenticated ? <Component {...props} />
          : <Redirect to="/login" />
      )
    )} />
  )
}

const App: FC = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <ProtectedRoute exact path="/" component={CarbonTracker} />
            <ProtectedRoute exact path="/api-key" component={APIKey} />
            <ProtectedRoute exact path="/billing" component={Billing} />
            <ProtectedRoute exact path="/link" component={ConnectBank} />
            <ProtectedRoute exact path="/history" component={History} />
            <ProtectedRoute exact path="/logout" component={LogOut} />
            <ProtectedRoute exact path="/graph" component={Graph} />
            <ProtectedRoute exact path="/projects" component={Projects} />
            <ProtectedRoute exact path="/orders" component={Orders} />
            <ProtectedRoute exact path="/baseline" component={Baseline} />
            <Route exact path="/crypto" component={CryptoCalculator} />
            <Route exact path="/checkout/success" component={CheckoutSuccess} />
            <Route exact path='/404' component={NotFound} />
            <Route exact path="/login" component={LogIn} />
            <Route exact path="/example" component={Example} />
            <Route exact path="/survey" component={Survey} />
            <Route path="/receipt/22ece6f9a7a747debfd1779b40395b63" component={ReceiptDemo} />
            <Route path="/receipt/b34984d384584c2caeeadbc67faed3c3" component={ReceiptDemo2} />
            <Route path="/receipt/demo/:name" component={ReceiptDemoRouter} />
            <Redirect from='*' to='/404' />
          </Switch>
        </Router>
        <ToastContainer hideProgressBar={true} />
      </QueryClientProvider>
    </div>
  );
}


export default App;
