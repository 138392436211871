import { CryptoFootprint, Order } from "./models";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
const CRYPTO_CALC_API_KEY = process.env.REACT_APP_CRYPTO_CALC_API_KEY || "7852b665bd854d81a1105083b9e27dd1";

export enum Method {
    GET = 'GET',
    POST = 'POST',
}

export const request = async (method: Method, path: string, body?: any, api_key?: string): Promise<any> => {
    const response = await fetch(`${API_BASE_URL}${path}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': api_key || CRYPTO_CALC_API_KEY,
        },
        body: body ? JSON.stringify(body) : undefined
    })

    return response.json();
}


export const authRequest = async (method: Method, path: string, body?: any, jwt_token?: string, api_key?: string): Promise<any> => {
    let headers: any = {
        'Content-Type': 'application/json',
    };

    if (jwt_token) {
        headers['Authorization'] = `Bearer ${jwt_token}`;
    }

    if (api_key) {
        headers['X-API-KEY'] = api_key;
    }

    const response = await fetch(`${API_BASE_URL}${path}`, {
        method: method,
        headers: headers,
        body: body ? JSON.stringify(body) : undefined
    });

    return response.json();
}

export interface CalculateCryptoEmissionRequest {
    addresses: {
        address: string;
        currency: "BTC";
    }[];
}


export interface CalculateCryptoEmissionResponse {
    footprints: CryptoFootprint[];
}


export const calculateCryptoEmission = async (req: CalculateCryptoEmissionRequest, apiKey?: string): Promise<CalculateCryptoEmissionResponse> => {
    return request(Method.POST, '/emission/calculate/crypto', req)
};

export interface CalculateCryptoEmissionByCustodyAverageRequest {
    currency: string;
    balance: number;
    start: Date;
    end: Date;
}


export const calculateCryptoEmissionByCustodyAverage = async (req: CalculateCryptoEmissionByCustodyAverageRequest, apiKey?: string): Promise<CryptoFootprint> => {
    return request(Method.POST, '/emission/calculate/crypto/average', req)
}




export const offsetFootprintCalculations = async (calculation_ids: string[], project_id: string, apiKey?: string): Promise<Order> => {
    return request(Method.POST, '/removal/offset/calculation/checkout', { calculation_ids, project_id })
}
