import moment from "moment";
import { useMemo } from "react";
import DashboardLayout from "../components/DashboardLayout";
import Table from "../components/Table";
import { useMyOrders, useProjects } from "../lib/hooks";
import { displayMoney, humanizeCO2eAmount } from "../lib/util";

const Orders = () => {
	const { orders, isLoading } = useMyOrders();
	const { projects } = useProjects();

	const columns = useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'date', // accessor is the "key" in the data
			},
			{
				Header: 'Name',
				accessor: 'name', // accessor is the "key" in the data
			},
			{
				Header: 'Status',
				accessor: 'status', // accessor is the "key" in the data
			},
			{
				Header: 'Amount',
				accessor: 'amount', // accessor is the "key" in the data
			},
			{
				Header: 'Price',
				accessor: 'price',
			}
		], []
	);

	const data = useMemo(
		() => (orders || [])
			.sort(
				(a, b) => moment(b.timestamps['created']).unix() - moment(a.timestamps['created']).unix()
			)
			.map(c => {
				const project = projects.find(p => p.id === c.project_id);

				return {
					date: moment(c.timestamps['created']).format("YYYY/MM/DD"),
					name: `${project?.provider || ""} ${project?.name || ""}`,
					status: c.status === "charged" ? "pending" : c.status,
					amount: humanizeCO2eAmount(c.amount_co2_kg, 'kg'),
					price: displayMoney(c.total_cents),
				}
			}), [orders, projects]
	);

	return (
		<DashboardLayout>
			<div className="flex flex-col w-full mb-4">
				<div className="flex flex-row w-full items-center mb-4">
					<h1 className="font-bold md:text-3xl text-xl font-dm-sans text-canopi-dark-green">Orders</h1>
				</div>
				<Table columns={columns} data={data} loading={isLoading} />
				<p className="md:text-base text-base font-dm-sans text-gray-500 mt-6 max-w-2xl">
					If you need to remove an order, please email taishi@canopi.cash and we will do that asap. We are working to add this functionality directly from our dashboard.
				</p>
			</div>
		</DashboardLayout>
	)

}

export default Orders;