export interface User {
	authsub: string;
	display_name: string;
	given_name: string;
	family_name: string;
	picture: string;
	stripe_customer_id: string;
	business_data?: BusinessData;
}

export interface BusinessData {
	organization_name: string;
	industry: string;
	location: string;
	num_employees: number;
	office_policy: string;
	baseline_emissions: BaselineEmissions;
	timestamp: Date;
}

export interface BaselineEmissions {
	model_version: string;
	timestamp: Date;
	monthly_total: number;
	unit: string;
}

export interface TotalEmission {
	total: number;
	unit: string;
	query_ts?: Date;
	start_ts?: Date;
	end_ts?: Date;
}

export interface TotalOffset {
	total: number;
	total_filled: number;
	unit: string;
	num_orders: number;
}

export interface MyStats {
	total_emission: TotalEmission;
	total_offset: TotalOffset;
	connected_items: number;
}

export interface Transaction {
	amount: {
		value: number;
		currency: string;
	};
	reference: string;
	timestamp: Date;
	note: string;

	mcc?: string;
	plaid_category_code?: string;

	ignore: boolean;
	deposit: boolean;

	name: string;
	merchant_name: string;
	payment_channel: string;
	plaid_categories: string[];
}

export interface TransactionFootprint {
	uuid: string;
	type: 'transaction';
	transaction?: Transaction;
	category_id: number;
	emission: Emission;
}

// as returned by /emission/history
export type Calculation = TransactionFootprint | CryptoFootprint;

export interface TimeRangeEmissionStats {
	[ts: string]: {
		total: number;
		unit: 'kg';
		count: number;
		emission_by_category: {
			[category_id: string]: number;
		};
	}
}

export interface TimeRangeStats {
	emissions: {
		stats: TimeRangeEmissionStats;
		category_desc: { [id: string]: string[] };
	};
	orders: {
		stats: {
			[ts: string]: TotalOffset;
		}
	};
}


export enum CarbonStatus {
	NoCalculations = "No calculations",
	NotCarbonNeutral = "Not carbon neutral",
	CarbonNeutral = "Carbon neutral",
	CarbonNegative = "Carbon negative",
}


export interface PaymentMethod {
	type: 'card';
	stripe_payment_method_id: string;
	card_brand: string;
	card_last4: string;
	card_fingerprint: string;
	card_exp_year: number;
	card_exp_month: number;
}

export interface AddressDetails {
	address: string;
	currency: string;
	end_ts: Date;
	query_ts: string;
	scope_desc: string;
	start_ts: Date;
	total_transactions: number;
	total_transactions_in_scope: number;
	total_gas_used: number;
	total_gas_in_scope: number;
	is_contract?: boolean;
	balance: number;
}

export interface Emission {
	lower: number;
	model_version: string;
	timestamp: string;
	total: number;
	unit: string;
	upper: number;
}

export interface CryptoFootprint {
	address_details: AddressDetails;
	emission: Emission;
	type: 'crypto';
	uuid: string;
}

export interface Project {
	id: string;
	provider: string;
	name: string;
	slug: string;
	cents_per_kg: number;
	images: string[];
	project_url: string;
	description: string;
}

export interface Order {
	amount_co2_kg: number;
	calculation_ids: string[];
	cents_per_kg: number;
	id: string;
	project_id: string;
	project_name: string;
	project_slug: string;
	status: string;
	stripe_session_id: string;
	timestamps: { [status: string]: Date };
	total_cents: number;
}