import { Redirect, useParams } from "react-router-dom";
import ReceiptDemoFlight from "./ReceiptDemoFlight";
import ReceiptDemoRide from "./ReceiptDemoRide";

const ReceiptDemoRouter = () => {

	const { name } = useParams<{ name: string }>();

	const flights = {
		"surfair": {
			name: "Surf Air",
			logo_url: "https://upload.wikimedia.org/wikipedia/commons/1/11/Surf_Air_logo.svg",
		},
		"aero": {
			name: "Aero",
			logo_url: "https://media-exp1.licdn.com/dms/image/C4E0BAQFi40BYoJOxgQ/company-logo_200_200/0/1570123257522?e=1635379200&v=beta&t=TfJvzclSLvLRvpOz2lh9bjS_hZpf5dKPjV4wNGLO5Vw",

		},
		"victor": {
			name: "Victor",
			logo_url: "https://maddyness-uk.twic.pics/2020/01/fly-victor-logo.png?twic=v1/dpr=2/resize=630",

		},
		"sentient-jet": {
			name: "Sentient Jet",
			logo_url: "https://fly.sentient.com/wp-content/uploads/2018/10/SENTIENTJET-Logo-KO-003e52.png",
		},
		"tourlane": {
			name: "Tourlane",
			logo_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Tourlane_logo_black.svg/1280px-Tourlane_logo_black.svg.png",
			activity: "traveling",
		},
	};

	const rides = {
		"via": {
			name: "Via",
			logo_url: "https://upload.wikimedia.org/wikipedia/en/0/04/Via_rideshare_logo.png"
		},
		"bus.com": {
			name: "Bus.com",
			logo_url: "https://uploads-ssl.webflow.com/60a768ac976f3d8ac7d87081/6100eb887aa279f55951a231_Screen%20Shot%202021-07-27%20at%2010.30.35%20PM.png",
		},
	}


	if (Object.keys(flights).includes(name)) {
		const val = (flights as any)[name];
		return <ReceiptDemoFlight name={val.name} logo_url={val.logo_url} activity={val?.activity || undefined} />
	} else if (Object.keys(rides).includes(name)) {
		const val = (rides as any)[name];
		return <ReceiptDemoRide name={val.name} logo_url={val.logo_url} />
	} else {
		return <Redirect to="/404" />
	}

}

export default ReceiptDemoRouter;