import { useAuth0 } from '@auth0/auth0-react';
import { FC, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { authRequest, Method } from '../lib/datalayer';

interface Props {
    linkToken: string;
    intern?: boolean;
    onComplete?: () => void;
}


const PlaidLinkButton: FC<Props> = ({ linkToken, onComplete }: Props) => {
    const { getAccessTokenSilently } = useAuth0();

    const onSuccess = useCallback(async (public_token, metadata) => {
        try {
            // send public_token to server
            const accessToken = await getAccessTokenSilently();
            const data = await authRequest(Method.POST, '/user/link/exchange-token', { public_token }, accessToken);
            console.log("exchanged token: ", data);
            if (onComplete) onComplete();
        } catch (error) {
            console.error("error exchanging token: ", error)
        }
    }, [onComplete, getAccessTokenSilently])

    const config: Parameters<typeof usePlaidLink>[0] = {
        token: linkToken!,
        onSuccess,
    }

    const { open, ready } = usePlaidLink(config);

    return (
        <button onClick={() => open()} disabled={!ready} >
            Connect Bank
        </button>
    )
}


export default PlaidLinkButton;