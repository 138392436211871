import React from 'react';
import ReactDOM from 'react-dom';
import Analytics from 'analytics';
import { AnalyticsProvider } from 'use-analytics';
import { Auth0Provider } from '@auth0/auth0-react';
import segmentPlugin from '@analytics/segment';
import './index.css';
import './tailwind.output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const analytics = Analytics({
  app: 'canopi-web-app',
  plugins: [
    segmentPlugin({
      writeKey: 'EfvQl6mIARhK1PRCXIsBmrL3dl4R1M7y',
    })
  ]
})

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="canopi.us.auth0.com"
      clientId="EdM94mMAbkFpsBHxPAblphxhPJCWCl8X"
      redirectUri={window.location.origin}
      audience="https://backend.canopi.cash"
    >
      <AnalyticsProvider instance={analytics}>
        <App />
      </AnalyticsProvider>

    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
