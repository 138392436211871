/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Project } from '../lib/models'
import { displayMoney, humanizeCO2eAmount } from '../lib/util'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useCreateOrder } from '../lib/hooks'

export default function CreateOrderOverlay({ open, setOpen, project, onComplete, hardcodeAmountCO2kg }: { open: boolean; setOpen: any; project: Project; onComplete?: any, hardcodeAmountCO2kg?: number }) {
	const { register, handleSubmit, watch } = useForm();
	const history = useHistory();
	const createOrder = useCreateOrder();
	const [isOffsetLoading, setIsOffsetLoading] = useState(false);

	const amountInput = watch("amount_ton_co2");
	let amount_kg_co2 = hardcodeAmountCO2kg || 0;
	if (!isNaN(amountInput)) {
		amount_kg_co2 = parseFloat(amountInput) * 1000 || 0;
	}
	const totalCents = amount_kg_co2 * project.cents_per_kg;
	const offsetDisabled = totalCents < 50;

	const onSubmit = async (event: any) => {
		setIsOffsetLoading(true);
		try {
			console.log(event);
			console.log(project);
			const order = await createOrder(project, amount_kg_co2);
			console.log(order);
			if (order.error) {
				throw order.error;
			}
			toast("Created an order");
			history.push("/orders");
		} catch (error) {
			toast(`Error: ${error}`)
			if (error === "payment method doesn't exist") {
				history.push("/billing")
			}
		} finally {
			setOpen(false);
			setIsOffsetLoading(false);
		}
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<form
							onSubmit={handleSubmit(onSubmit)}
							className="inline-block align-bottom font-dm-sans bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:max-w-lg sm:max-w-sm sm:w-full sm:p-6"
						>
							<div className="text-center pb-2">
								<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
									Create an order
								</Dialog.Title>
							</div>
							<div className="relative my-2">
								<div className="absolute inset-0 flex items-center" aria-hidden="true">
									<div className="w-full border-t border-gray-300" />
								</div>
							</div>

							<div className="border-gray-200 px-4 py-5 sm:p-0">
								<dl className="sm:divide-y sm:divide-gray-200">
									<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500">Provider</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{project.provider}</dd>
									</div>
									<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500">Project</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{project.name}</dd>
									</div>
									<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500">Price</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{displayMoney(project.cents_per_kg * 1000)} / ton</dd>
									</div>
									<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500">Description</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{project.description}</dd>
									</div>
									<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt className="text-sm font-medium text-gray-500">Amount</dt>
										<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
											<div>
												{hardcodeAmountCO2kg ? (
													<p>{humanizeCO2eAmount(hardcodeAmountCO2kg, 'kg')}</p>
												) : (
													<div className="relative">
														<input
															className="relative w-full bg-white font-dm-sans border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-canopi-green focus:border-canopi-green text-lg"
															placeholder={"1000"}
															type="text"
															{...register("amount_ton_co2")}
														/>
														<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
															<span className="text-gray-500 sm:text-sm" id="price-currency">
																ton CO2
															</span>
														</div>
													</div>

												)}
											</div>
										</dd>
									</div>
								</dl>
							</div>

							<div className="mt-5 sm:mt-6 flex mx-auto justify-center">
								<button
									type="submit"
									onClick={onComplete}
									className={`bg-canopi-green disabled:opacity-50 ${offsetDisabled ? "cursor-not-allowed" : "hover:bg-green-700"}  text-white text-base font-bold font-dm-sans py-2 px-4 rounded-md focus:outline-none focus:shadow-outline mr-2 inline-flex justify-center items-center`}
									disabled={offsetDisabled}
									style={{ minWidth: "120px" }}
								>
									{isOffsetLoading ? (
										<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
											<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>

									) : (
										<span className="text-white font-bold text-base font-dm-sans leading-6">
											Confirm - {displayMoney(totalCents)}
										</span>
									)}
								</button>
							</div>
						</form>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
