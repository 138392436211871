import { PlusIcon } from '@heroicons/react/solid'

import { ClipboardIcon } from '@heroicons/react/outline';

const Empty = ({ title, description, button, icon }: { title: string; description: string; button?: any, icon?: any }) => {
	return (
		<div className="text-center m-3 font-dm-sans">
			{icon && <ClipboardIcon className="mx-auto h-12 w-12 p-2" />}
			<h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
			<p className="mt-1 text-sm text-gray-500">{description}</p>
			{button && (
				<div className="mt-6">
					{button}
				</div>
			)}
		</div>
	)

}

export default Empty;