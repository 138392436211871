import { FC } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { useGetAPIKey, useUserData } from '../lib/hooks';


const APIKeyWidget: FC<{ apiKey: string }> = ({ apiKey }: any) => {
	return (
		<div className="bg-white rounded-2xl font-dm-sans">
			<div className="px-4 py-5 sm:p-6">
				<h3 className="text-lg leading-6 font-medium text-gray-900">Your API key</h3>
				<div className="mt-4 mb-2 sm:flex sm:items-start sm:justify-between">
					<div className="max-w-xl text-2xl font-bold text-canopi-green">
						<p>
							{apiKey}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}


const APIKey = () => {
	const { user } = useUserData();
	const { apiKey } = useGetAPIKey();

	const displayName = user ? user.display_name : "";

	return (
		<DashboardLayout>
			<div className="flex flex-col w-full mb-4">
				<div className="flex flex-row w-full items-center mb-4">
					<h1 className="font-bold md:text-3xl text-xl font-dm-sans text-canopi-dark-green">{`API Key`}</h1>
				</div>
				<div className="flex flex-col mb-8">
					<p className="font-medium md:text-xl text-base font-dm-sans text-canopi-dark-green">
						Thank you for trying out Canopi's Climate API.
					</p>
				</div>
				<div className="mt-4">
					<APIKeyWidget apiKey={apiKey} />
				</div>
				<p className="font-medium md:text-xl text-lg font-dm-sans text-canopi-dark-green mt-12">
					References
				</p>
				<p className="font-medium md:text-base text-base font-dm-sans text-canopi-dark-green mt-4">
					<a className="underline" target="_blank" rel="noreferrer" href="https://www.canopi.cash/api-docs">API Documentation</a>
				</p>
				<p className="font-medium md:text-base text-base font-dm-sans text-canopi-dark-green mt-4">
					<a className="underline" target="_blank" rel="noreferrer" href="https://github.com/taishi8117/canopi-js">JavaScript API Client Library</a>
				</p>
			</div>
		</DashboardLayout>
	)
}

export default APIKey;