import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { Project } from "./models";

export const displayMoney = (amount: number, options: { currency?: string, sign?: boolean, space?: boolean } | undefined = { currency: "USD", sign: true, space: true }): string => {
	return `${options.sign && amount < 0 ? '-' : ''}$${options.space ? ' ' : ''}${(Math.abs(amount) / 100.0).toFixed(2)}`
}


export const humanizeCryptoAmount = (amount: number, currency: string) => {
	switch (currency) {
		case 'BTC':
			return amount / 1e8;
		case 'ETH':
			return amount / 1e18;
		default:
			console.error("unknown currency")
			return amount;
	}
}

export const numberWithCommas = (x: number) => {
	var parts = x.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

// 105 => 105 kg
// 384040 => 384.04 tons
// 345353535 => 345,353.54 tons
export const humanizeCO2eAmount = (amount: number, unit: 'kg', outUnit: 'kg' | 'ton' = 'ton') => {
	if (outUnit === 'kg') {
		return `${Math.round(amount)} kg`
	}


	const tons = amount / 1000;
	const unitStr = (tons <= 1.0 ? ' ton' : ' tons');

	return `${numberWithCommas(round(tons, 1))}${unitStr}`
}

function round(value: number, precision: number) {
	// https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
	var multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
}

export const calculateOffsetPrice = (project: Project, emissionKg: number): number => {
	return Math.ceil(project.cents_per_kg * emissionKg);
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export const getMonthRef = (dt: Date) => moment(new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), 1))).utc();

export const getThisMonthStr = () => {
	return getMonthRef(moment().toDate()).format("MMMM YYYY")
}