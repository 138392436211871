import moment from 'moment';
import { FC, useEffect, useState, Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { calculateCryptoEmission, calculateCryptoEmissionByCustodyAverage, offsetFootprintCalculations } from '../lib/datalayer';
import BTCIcon from '../components/BTCIcon';
import Nav from '../components/Nav';
import ProjectCard from '../components/ProjectCard';
import ReactTooltip from 'react-tooltip';
import { displayMoney, humanizeCryptoAmount, stripePromise } from '../lib/util';
import { useAnalytics } from 'use-analytics';
import { Listbox, Transition, Switch } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import ETHIcon from '../components/ETHIcon';
import { useGetAPIKey, usePachamaProject } from '../lib/hooks';
import { CryptoFootprint, Project } from '../lib/models';


const calculateOffsetPrice = (project: Project, emissionKg: number): number => {
	return Math.ceil(project.cents_per_kg * emissionKg);
}

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(' ')
}

const sampleETHAddr = "0x89205A3A3b2A69De6Dbf7f01ED13B2108B2c43e7";
const sampleBTCAddr = "1JAo836YNkXZr22SwwQSrAKCViyrkBLfop";

const currencies = [
	{
		id: 1,
		name: 'Bitcoin',
		avatar:
			'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/BTC_Logo.svg/2000px-BTC_Logo.svg.png',
		code: 'BTC',
	},
	{
		id: 2,
		name: 'Ethereum',
		avatar:
			'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ethereum-icon-purple.svg/1200px-Ethereum-icon-purple.svg.png',
		code: 'ETH',
	},
]

const years = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021].reverse();

const CryptoCalculator: FC = () => {
	const { register, handleSubmit, control } = useForm();
	const [footprints, setFootprints] = useState<CryptoFootprint[]>([]);
	const { track, page } = useAnalytics();
	const { apiKey } = useGetAPIKey();

	const [isCalcLoading, setIsCalcLoading] = useState<boolean>(false);
	const [isOffsetLoading, setIsOffsetLoading] = useState<boolean>(false);
	const [selectedCurrency, setSelectedCurrency] = useState<any>(currencies[0]);
	const [calcFromAddress, setCalcFromAddress] = useState<boolean>(false);
	const [yearSince, setYearSince] = useState<number>(2020);


	const { project, error: projectError } = usePachamaProject();
	const [error, setError] = useState<any>(null);


	const totalCents = project ? footprints.reduce((acc, v) => acc + calculateOffsetPrice(project, v.emission.total), 0) : 0;
	const totalEmissions = footprints.reduce((acc, v) => acc + v.emission.total, 0);


	useEffect(() => {
		document.title = "Crypto Carbon Footprint Calculator - Canopi";
		page();
	}, [page])

	const onOffsetFootprint = async (calculation_ids: string[]) => {
		setIsOffsetLoading(true);
		try {
			if (project === null) {
				throw Error("project not selected");
			}
			const order = await offsetFootprintCalculations(calculation_ids, project.id, apiKey)

			const stripe = await stripePromise;

			console.log("new order: ", order);
			track('onOffsetFootprint',
				{
					calculation_ids,
					stripe_session_id: order.stripe_session_id,
					order_id: order.id,
				}
			);

			const result = await stripe?.redirectToCheckout({
				sessionId: order.stripe_session_id,
			});

			console.log(result);

			if (result?.error) {
				console.log("stripe result error: ", result?.error);
				throw result?.error;
			}

		} catch (error) {
			console.log("error: ", error);
			track('onOffsetFootprint_error', { calculation_ids, error });
		} finally {
			setIsOffsetLoading(false);
		}
	}

	const onRemoveFootprint = (id: string) => {
		setFootprints(footprints.filter(v => v.uuid !== id));
		track('onRemoveFootprint', { calculation_id: id });
	}


	const onSubmit = async (params: any) => {
		console.log("onSubmit: ", params);

		setIsCalcLoading(true);
		setError(null);

		if (calcFromAddress) {
			// calculate from address
			const address = params.address;
			try {
				const data = await calculateCryptoEmission({ addresses: [{ address, currency: selectedCurrency.code }] }, apiKey)
				console.log(data);

				if ((data as any).error) {
					throw data;
				}

				const fs = data.footprints || [];

				setFootprints(arr => [...arr, ...fs]);
				track('onCalculateCryptoEmission', { address, currency: selectedCurrency.code, calcFromAddress });

			} catch (error) {
				console.log("Error: ", error);
				setError(error);
				track('onCalculateCryptoEmission_error', { address, currency: selectedCurrency.code, error });
			} finally {
				setIsCalcLoading(false);
			}
		} else {
			// calculate with custody average 

			var req = {
				currency: selectedCurrency.code,
				balance: parseFloat(params.balance),
				start: moment.utc(`${yearSince}-01-01 00:00:00.000`).toDate(),
				end: moment().toDate(),
			}

			try {
				const fp = await calculateCryptoEmissionByCustodyAverage(req, apiKey);
				console.log(fp);
				if ((fp as any).error) {
					throw fp;
				}

				setFootprints(arr => [...arr, fp])
				track('onCalculateCryptoEmission', { balance: req.balance, currency: req.currency, calcFromAddress, start: req.start, end: req.end });

			} catch (error) {
				console.log("error: ", error);
				setError(error);
				track('onCalculateCryptoEmission_error', { ...req, error });
			} finally {
				setIsCalcLoading(false);
			}
		}

	};

	return (
		<div className="h-full w-full">
			<Nav />
			<div className="px-4 pt-6 pb-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
				<div className="container flex flex-col lg:px-12">
					<div className="flex flex-row w-full items-center mb-4">
						<BTCIcon width={"3em"} height={"3em"} className="mr-4 hidden md:block" />
						<BTCIcon width={"2em"} height={"2em"} className="mr-2 md:hidden" />
						<h1 className="font-bold md:text-3xl text-xl font-dm-sans text-canopi-dark-green">Crypto Carbon Footprint Calculator</h1>
					</div>

					<div className="flex flex-col mb-8">
						<p className="font-medium md:text-xl text-base font-dm-sans text-canopi-dark-green">
							Measure carbon footprint of your crypto and invest in climate solutions
						</p>
					</div>


					<div className="grid md:grid-cols-2 grid-cols-1 md:space-x-12">
						<div className="flex-1 order-last md:order-first">
							<form onSubmit={handleSubmit(onSubmit)} className="bg-white rounded-2xl md:px-8 px-4 md:pt-8 md:pb-8 pt-4 pb-4">
								<div className="flex flex-col mb-8 justify-start">
									{/** Currency selector */}
									<div className="mb-4">
										<Controller
											control={control}
											defaultValue={selectedCurrency}
											name="selectedCurrency"
											render={(prop) => (
												<Listbox
													as="div"
													value={prop.field.name}
													onChange={(e) => {
														prop.field.onChange(e);
														setSelectedCurrency(e);
														console.log("on change: ", prop, e);
													}}
												>
													{({ open }) => (
														<>
															<Listbox.Label className="block text-base font-dm-sans text-canopi-dark-green mb-2">Currency</Listbox.Label>
															<div className="relative">
																<Listbox.Button className="relative w-full bg-white font-dm-sans border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-canopi-green focus:border-canopi-green  sm:text-sm">
																	<span className="flex items-center">
																		<img src={selectedCurrency.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
																		<span className="ml-3 block truncate text-base">{selectedCurrency.name}</span>
																	</span>
																	<span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
																		<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
																	</span>
																</Listbox.Button>

																<Transition
																	show={open}
																	as={Fragment}
																	leave="transition ease-in duration-100"
																	leaveFrom="opacity-100"
																	leaveTo="opacity-0"
																>
																	<Listbox.Options
																		static
																		className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm font-dm-sans"
																	>
																		{currencies.map((person) => (
																			<Listbox.Option
																				key={person.id}
																				className={({ active }) =>
																					classNames(
																						active ? 'text-white bg-canopi-green' : 'text-gray-900',
																						'cursor-default select-none relative py-2 pl-3 pr-9'
																					)
																				}
																				value={person}
																			>
																				{({ selected, active }) => (
																					<>
																						<div className="flex items-center">
																							<img src={person.avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
																							<span
																								className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
																							>
																								{person.name}
																							</span>
																						</div>

																						{selected ? (
																							<span
																								className={classNames(
																									active ? 'text-white' : 'text-canopi-green',
																									'absolute inset-y-0 right-0 flex items-center pr-4'
																								)}
																							>
																								<CheckIcon className="h-5 w-5" aria-hidden="true" />
																							</span>
																						) : null}
																					</>
																				)}
																			</Listbox.Option>
																		))}
																	</Listbox.Options>
																</Transition>
															</div>
														</>
													)}
												</Listbox>

											)}
										/>
									</div>

									{calcFromAddress ? (
										<div>
											<label className="block text-canopi-dark-green text-base mb-2 font-dm-sans ">
												Your Address
											</label>
											<input
												className="shadow appearance-none border rounded w-full py-2 px-3 text-canopi-dark-green font-dm-sans text-lg leading-tight focus:outline-none focus:shadow-outline"
												placeholder={selectedCurrency.code === "BTC" ? sampleBTCAddr : sampleETHAddr}
												type="text"
												{...register("address")}
											/>
										</div>

									) : (
										<div className="flex flex-col space-y-4">
											<div>
												<label className="block text-canopi-dark-green text-base mb-2 font-dm-sans ">
													Your balance
												</label>
												<div className="relative">
													<input
														className="relative w-full bg-white font-dm-sans border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-canopi-green focus:border-canopi-green text-lg"
														placeholder={"0.0003"}
														type="text"
														{...register("balance")}
													/>
													<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
														<span className="text-gray-500 sm:text-sm" id="price-currency">
															{selectedCurrency.code}
														</span>
													</div>
												</div>
											</div>
											<div>
												<Controller
													control={control}
													defaultValue={yearSince}
													name="yearSince"
													render={(prop) => (
														<Listbox
															as="div"
															value={prop.field.name}
															onChange={(e) => {
																prop.field.onChange(e);
																setYearSince(Number.parseInt(e));
																console.log("on change: ", prop, e);
															}}
														>
															{({ open }) => (
																<>
																	<Listbox.Label className="block text-base font-dm-sans text-canopi-dark-green mb-2">Year acquired</Listbox.Label>
																	<div className="relative">
																		<Listbox.Button className="relative w-full bg-white font-dm-sans border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-canopi-green focus:border-canopi-green  sm:text-sm">
																			<span className="flex items-center">
																				<span className="block truncate text-base">{yearSince}</span>
																			</span>
																			<span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
																				<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
																			</span>
																		</Listbox.Button>

																		<Transition
																			show={open}
																			as={Fragment}
																			leave="transition ease-in duration-100"
																			leaveFrom="opacity-100"
																			leaveTo="opacity-0"
																		>
																			<Listbox.Options
																				static
																				className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm font-dm-sans"
																			>
																				{years.map((year) => (
																					<Listbox.Option
																						key={year}
																						className={({ active }) =>
																							classNames(
																								active ? 'text-white bg-canopi-green' : 'text-gray-900',
																								'cursor-default select-none relative py-2 pl-3 pr-9'
																							)
																						}
																						value={year}
																					>
																						{({ selected, active }) => (
																							<>
																								<div className="flex items-center">
																									<span
																										className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
																									>
																										{year}
																									</span>
																								</div>

																								{selected ? (
																									<span
																										className={classNames(
																											active ? 'text-white' : 'text-canopi-green',
																											'absolute inset-y-0 right-0 flex items-center pr-4'
																										)}
																									>
																										<CheckIcon className="h-5 w-5" aria-hidden="true" />
																									</span>
																								) : null}
																							</>
																						)}
																					</Listbox.Option>
																				))}
																			</Listbox.Options>
																		</Transition>
																	</div>
																</>
															)}
														</Listbox>

													)}
												/>
											</div>
										</div>
									)}
								</div>
								<div className="flex flex-row justify-between items-center">
									<div >
										<button className="w-36 inline-flex justify-center items-center px-4 py-2 rounded-md bg-canopi-green hover:bg-green-700 focus:outline-none focus:shadow-outline" type="submit">
											{isCalcLoading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>

											) : (
												<span className="text-white font-bold text-base font-dm-sans leading-6">Calculate</span>
											)}
										</button>
									</div>
									<Switch.Group as="div" className="flex items-center">
										<Switch
											checked={calcFromAddress}
											onChange={setCalcFromAddress}
											className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-canopi-green"
										>
											<span className="sr-only">Use setting</span>
											<span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
											<span
												aria-hidden="true"
												className={classNames(
													calcFromAddress ? 'bg-canopi-green' : 'bg-gray-200',
													'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
												)}
											/>
											<span
												aria-hidden="true"
												className={classNames(
													calcFromAddress ? 'translate-x-5' : 'translate-x-0',
													'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
												)}
											/>
										</Switch>
										<Switch.Label as="span" className="ml-3">
											<span className="text-sm text-gray-700 font-dm-sans">Calculate by address</span>
										</Switch.Label>
									</Switch.Group>

								</div>
							</form>
							{(error || projectError) &&
								<p className="text-base font-dm-sans text-canopi-red">
									Error occurred. Please make sure you enter a valid BTC address.
								</p>
							}
						</div>

						<div className="flex flex-col  justify-between text-canopi-dark-green md:text-lg text-base font-dm-sans order-first md:order-last mb-6 md:mb-0">
							<span>
								<p className="font-medium md:text-xl text-xl font-dm-sans text-canopi-dark-green mb-4">
									How it works
								</p>
								<p className="mb-2">
									Enter your crypto addresses to calculate your wallet's emissions.
									Invest in carbon offset solutions with Pachama.
								</p>
								<p className="mb-4">
									Dive into our <a className="underline" target="_blank" rel="noreferrer" href="https://www.canopi.cash/crypto/explainer">explainer</a>, <a className="underline" target="_blank" rel="noreferrer" href="https://www.canopi.cash/crypto/methodology">methodology</a> and <a className="underline" target="_blank" rel="noreferrer" href="https://www.canopi.cash/crypto/faq">FAQs</a>.
								</p>
								<a
									href="https://www.producthunt.com/posts/crypto-carbon-footprint-calculator?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-crypto-carbon-footprint-calculator"
									target="_blank"
								>
									<img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303824&theme=light" alt="Crypto Carbon Footprint Calculator - Calculate & offset emissions from your cryptocurrencies | Product Hunt" width="250" height="54" />
								</a>

							</span>
							<span>
								<p className="border-t border-canopi-dark-green border-opacity-20 mx-2 my-2" />
								<p className="font-semibold">
									Powered by Canopi's <a className="underline" target="_blank" rel="noreferrer" href="https://www.canopi.cash/api-docs">Climate API</a>. Request access <a className="underline" target="_blank" rel="noreferrer" href="https://form.typeform.com/to/Z918Lf4B">here</a>.
								</p>

							</span>
						</div>

					</div>


					<div className="flex flex-col mt-8">
						<p className="font-bold md:text-2xl text-xl font-dm-sans text-canopi-dark-green">
							Your footprint
						</p>
					</div>

					<div className="flex items-center justify-center font-dm-sans">
						<div className="w-full">
							<div className=" mt-6 overflow-x-auto">
								<table className="min-w-max w-full table-auto">
									<thead className="overflow-hidden">
										<tr className="bg-gray-100 text-canopi-dark-green uppercase md:text-base text-sm leading-normal">
											<th className="py-3 px-6 text-left">From</th>
											<th className="py-3 px-6 text-center">Emissions</th>
											<th className="py-3 px-6 text-center">Investment</th>
											<th className="py-3 px-6 text-center"></th>
										</tr>
									</thead>
									<tbody className="bg-white text-gray-600 md:text-lg text-base font-light">
										{footprints.length > 0 ?
											footprints.map(f => (
												<tr className="border-b border-gray-200 hover:bg-gray-50" key={f.uuid}>
													<td className="py-3 px-6 text-left whitespace-nowrap">
														<div className="flex items-center" data-tip data-for={`calc-details-${f.uuid}`}>
															<div className="mr-2">
																{
																	f.address_details.currency === "ETH" ? (
																		<>
																			<ETHIcon width={"2em"} height={"2em"} className="hidden md:block" />
																			<ETHIcon width={"1em"} height={"1em"} className="md:hidden" />
																		</>
																	) : (
																		<>
																			<BTCIcon width={"2em"} height={"2em"} className="hidden md:block" />
																			<BTCIcon width={"1em"} height={"1em"} className="md:hidden" />
																		</>
																	)
																}
															</div>
															{f.address_details.address ? (
																<span className="font-medium">{f.address_details.address}</span>
															) : (
																<span className="font-medium">{`Holding ${humanizeCryptoAmount(f.address_details.balance, f.address_details.currency)} ${f.address_details.currency} since ${moment(f.address_details.start_ts).utc().year()}`}</span>
															)}
														</div>
														<ReactTooltip id={`calc-details-${f.uuid}`} place="top" effect="solid" className="font-dm-sans">
															{!f.address_details.address ? (
																<>
																	<p className="font-medium">{`Based on average balance of ${f.address_details.balance / 1e8} ${f.address_details.currency} between ${f.address_details.start_ts} and ${f.address_details.end_ts}`}</p>
																</>
															) : (
																f.address_details.currency === "BTC" ? (
																	<>
																		<p className="font-medium">Based on daily balance of this BTC address</p>
																		<p className="font-medium">Date range considered: {f.address_details.start_ts} - {f.address_details.end_ts}</p>
																		<p className="font-medium">Current balance: {f.address_details.balance / 1e8} BTC</p>
																	</>
																) : (
																	<>
																		<p className="font-medium">Based on total gas used by this ETH address</p>
																		<p className="font-medium">Is contract: {JSON.stringify(f.address_details.is_contract || false)}</p>
																		<p className="font-medium">Total gas used: {f.address_details.total_gas_in_scope} ({f.address_details.start_ts} - {f.address_details.end_ts})</p>
																	</>
																)
															)
															}
														</ReactTooltip>
													</td>
													<td className="py-3 px-6 text-center">
														<p data-tip data-for={`emission-${f.uuid}`} className="font-medium">{f.emission.total} kg CO<sub>2</sub></p>
														<ReactTooltip id={`emission-${f.uuid}`} place="top" effect="solid" className="font-dm-sans">
															{f.emission.lower !== 0 && (<p>{`Lower bound: ${f.emission.lower} kg`}</p>)}
															{f.emission.upper !== 0 && (<p>{`Upper bound: ${f.emission.upper} kg`}</p>)}
														</ReactTooltip>
													</td>
													{/*
										<td className="py-3 px-6 text-center">
											<p>{`${moment(f.address_details.start_ts).format('MM/DD/YYYY')} - ${moment(f.address_details.end_ts).format('MM/DD/YYYY')}`}</p>
														<p>{`${f.address_details.total_transactions_in_scope}/${f.address_details.total_transactions} (${f.address_details.scope_desc})`}</p>
														<p className="text-xs">{`${moment(f.address_details.start_ts).format('MM/DD/YYYY')} - ${moment(f.address_details.end_ts).format('MM/DD/YYYY')}`}</p>
										</td>
										*/}
													<td className="py-3 px-6 text-center">
														<p className="font-bold text-canopi-green">{project ? displayMoney(calculateOffsetPrice(project, f.emission.total)) : "Project must be selected"}</p>
													</td>
													<td className="py-3 px-6 text-center">
														<div className="flex item-center justify-center">
															<button
																onClick={() => onRemoveFootprint(f.uuid)}
																className="h-5 w-5 flex flex-shrink justify-center mx-auto items-center text-white transition border border-gray-300 rounded-full hover:border-gray-500 focus:outline-none"
															>
																<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="text-gray-300 h-3 w-3">
																	<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)) :
											<tr className="border-b border-gray-200">
												<td className="py-5 px-6 text-center whitespace-nowrap" colSpan={5}>
													<div className="flex items-center">
														<span className="font-medium">No calculations</span>
													</div>
												</td>
											</tr>

										}
									</tbody>
								</table>
							</div>
						</div>
					</div>


					<div className="flex flex-col mt-6">
						<p className="font-bold md:text-2xl text-xl font-dm-sans text-canopi-dark-green">
							Go carbon neutral with
						</p>
					</div>

					<div className="flex flex-col mt-6">
						<ProjectCard project={project} hardcode="pachama" onClick={() => onOffsetFootprint(footprints.map(f => f.uuid))} totalCents={totalCents} emission={totalEmissions} isOffsetLoading={isOffsetLoading} />
					</div>

					<div className="flex flex-row mt-12 space-x-4 font-dm-sans text-xs items-center justify-center text-gray-600">
						<p>Copyright Canopi 2021 - All Rights Reserved.</p>
						<a className="underline" target="_blank" rel="noreferrer" href="https://www.canopi.cash/terms-of-service">Terms of Service</a>
						<a className="underline" target="_blank" rel="noreferrer" href="https://www.canopi.cash/privacy-policy">Privacy Policy</a>
					</div>
					{/*
					<div className="flex flex-row">
						<div className="max-w-xs">
							{selectedProject &&
								<div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
									<p className="font-bold text-base">{selectedProject.name}</p>
									<img src={selectedProject.images[0] || "#"} alt={selectedProject.name} className="shadow rounded max-w-full h-auto align-middle border-none" />
								</div>
							}
						</div>
					</div>

					*/}
				</div>
			</div>
		</div >
	)

}

export default CryptoCalculator;