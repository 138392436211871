import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PlaidLinkButton from '../components/PlaidLinkButton';
import { authRequest, Method } from '../lib/datalayer';

const ConnectBank: FC = () => {
	const { getAccessTokenSilently } = useAuth0();
	const [items, setItems] = useState(null);
	const [linkToken, setLinkToken] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const accessToken = await getAccessTokenSilently();
				const { item_ids } = await authRequest(Method.GET, '/user/link/connected-items', null, accessToken)
				if (!item_ids || item_ids.length === 0) {
					const { token }: any = await authRequest(Method.POST, '/user/link/create-token', null, accessToken)
					console.log("link token created: ", token);
					setItems(null);
					setLinkToken(token as string);
				} else {
					setItems(item_ids);
					setLinkToken(null);
				}
			} catch (error) {
				console.error("error: ", error);
			} finally {
				setLoading(false);
			}
		}
		fetchData();
	}, [getAccessTokenSilently]);

	if (loading) return (
		<div className="flex h-full w-full">
			<p>Loading</p>
		</div>
	)

	if (items) {
		return <Redirect to="/gocarbonneutral" />
	}

	return (
		<div className="flex h-full w-full">
			{linkToken && (
				<PlaidLinkButton linkToken={linkToken} onComplete={async () => {
					setLoading(true);
					setLinkToken(null);
					const accessToken = await getAccessTokenSilently();
					const { item_ids } = await authRequest(Method.GET, '/user/link/connected-items', null, accessToken)
					setItems(item_ids);
					setLoading(false);
				}} />
			)}
			{items &&
				<p>{JSON.stringify(items)}</p>}
		</div>
	)
}

export default ConnectBank;