import { useCallback } from 'react';
import { useEffect } from 'react';
import { FC } from 'react';
import { toast } from 'react-toastify';
import DashboardLayout from '../components/DashboardLayout';
import PaymentMethodWidget from '../components/PaymentMethod';
import { useCreateSaveCardCheckoutSession, useDefaultPaymentMethod, useURLQuery } from '../lib/hooks';
import { stripePromise } from '../lib/util';

const Billing = () => {
	const successURL = `${window.location.origin}/billing?session_id={CHECKOUT_SESSION_ID}&success=true`
	const cancelURL = `${window.location.origin}/billing?success=false`

	const { createSession } = useCreateSaveCardCheckoutSession(successURL, cancelURL);
	const { exists: paymentExists, paymentMethod, isLoading } = useDefaultPaymentMethod();

	const query = useURLQuery();
	const success = query.get("success")

	useEffect(() => {
		if (success === "true") {
			toast("Updated payment method");
		}

	}, [success])

	const onSaveNewCard = useCallback(async () => {
		try {
			const session_id = await createSession();
			console.log("session id: ", session_id);
			const stripe = await stripePromise;

			const result = await stripe?.redirectToCheckout({
				sessionId: session_id || "",
			})
			console.log(result);
			if (result?.error) {
				console.log("stripe result error: ", result!.error);
				throw result!.error;
			}
		} catch (error) {
			console.log("error: ", error);
		}
	}, [createSession]);


	return (
		<DashboardLayout>
			<div className="flex flex-col w-full mb-4">
				<h1 className="font-bold md:text-3xl text-xl font-dm-sans text-canopi-dark-green mb-4">Billing</h1>
				<div className="w-full max-w-screen-lg ">
					<div className="flex-1">
						<PaymentMethodWidget paymentMethod={paymentMethod} onAddNewCard={onSaveNewCard} loading={isLoading} />
					</div>
				</div>
			</div>
		</DashboardLayout>
	)
}

export default Billing;